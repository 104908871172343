/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import "./App.css";
import SignInScreen from "./components/SignInScreen";
import { auth } from "./services/firebase.config";
import logo from "./assets/logo-inverted.png";
import Home from "./pages/home/Home";
import Button from "@material-ui/core/Button";

const App: FC = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isMember, setIsMember] = useState(false);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      if (user) {
        user.getIdTokenResult().then((tokenResult) => {
          console.log(tokenResult);
          if (tokenResult.claims.role === "member") {
            setIsMember(true);
          }
        });
      }
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (!isSignedIn) {
    return (
      <div className="login-page">
        <img src={logo} alt="Whiskylogen logo" className="logo" />
        <SignInScreen />
      </div>
    );
  }

  if (!isMember) {
    return (
      <div className="login-page">
        <img src={logo} alt="Whiskylogen logo" className="logo" />
        <p>Du har ikke tilgang til denne siden!</p>
        <Button variant="contained" onClick={() => auth.signOut()}>
          Logg ut
        </Button>
      </div>
    );
  }

  return <Home />;
};

export default App;
