import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAIBhIuQud2NDiElRWZJlv0VLUJnupmaF8",
  authDomain: "whiskylogen.no",
  databaseURL: "https://whiskylogen-2ff36.firebaseio.com",
  projectId: "whiskylogen-2ff36",
  storageBucket: "whiskylogen-2ff36.appspot.com",
  messagingSenderId: "1004016356075",
  appId: "1:1004016356075:web:d7891107a756d41d7f0cae",
  measurementId: "G-LHP7X7WJ1F",
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
