import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { auth } from "../../services/firebase.config";
import { getWhiskies } from "../../services/whiskiesService";
import { WhiskyInformation } from "../../types";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  userName: {
    marginRight: theme.spacing(4),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  table: {
    minWidth: 650,
  },
}));

const Home: FC = () => {
  const classes = useStyles();
  const [whiskies, setWhiskies] = useState<WhiskyInformation[]>();

  useEffect(() => {
    getWhiskies().then((whiskies) => {
      setWhiskies(whiskies);
    });
  }, []);

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Whiskylogen
          </Typography>
          <Typography variant="h6" align="right" className={classes.userName}>
            {auth.currentUser?.displayName}
          </Typography>
          <Button color="inherit" onClick={() => auth.signOut()}>
            Logg ut
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.heroContent}>
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Velkommen!
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            Logen sin egen side er under utvikling. Innhold som bedre analyse og
            statistikk av inventar, oversikt over medlemmer, vedtekter og mye
            mer er på vei. Ta en god whisky og vent med tålmodighet. Slàinte
            Mhath!
          </Typography>
        </Container>
      </div>
      {whiskies && (
        <Container maxWidth="lg">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Liste over whisky">
              <caption>
                Totalpris for samlingen er:{" "}
                {whiskies.reduce(
                  (accumulator, whisky) => accumulator + whisky.originalPrice,
                  0
                )}{" "}
                kr
              </caption>
              <TableHead>
                <TableRow>
                  <TableCell>Navn</TableCell>
                  <TableCell align="right">Produsent/Tapper</TableCell>
                  <TableCell align="right">Alder</TableCell>
                  <TableCell align="right">Fyllnivå (cl)</TableCell>
                  <TableCell align="right">Fattype</TableCell>
                  <TableCell align="right">Land</TableCell>
                  <TableCell align="right">Merknad</TableCell>
                  <TableCell align="right">ABV (%)</TableCell>
                  <TableCell align="right">Nypris</TableCell>
                  <TableCell align="right">Lokasjon</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {whiskies.map((whisky) => (
                  <TableRow key={whisky.id}>
                    <TableCell component="th" scope="row">
                      {whisky.name}
                    </TableCell>
                    <TableCell align="right">{whisky.producer}</TableCell>
                    <TableCell align="right">{whisky.age}</TableCell>
                    <TableCell align="right">{whisky.fillLevel}</TableCell>
                    <TableCell align="right">{whisky.cask}</TableCell>
                    <TableCell align="right">{whisky.country}</TableCell>
                    <TableCell align="right">{whisky.note}</TableCell>
                    <TableCell align="right">{whisky.ABV}</TableCell>
                    <TableCell align="right">{whisky.originalPrice}</TableCell>
                    <TableCell align="right">{whisky.location}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
};

export default Home;
