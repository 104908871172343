import { WhiskyInformation } from "../types";
import { db } from "./firebase.config";

export async function getWhiskies(): Promise<WhiskyInformation[]> {
  const querySnapshot = await db.collection("whiskies").get();

  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() } as WhiskyInformation;
  });
}
